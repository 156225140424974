export default {
  groupPlaceholder: '选择分组搜索',
  belong: '归属：',
  datePlaceholder: '选择删除时间搜索',
  browserNamePlaceholder: '输入名称搜索',
  remarkPlaceholder: '输入备注搜索',
  showAll: '展示全部窗口',
  showSelf: '展示自建窗口',
  recoverSelection: '恢复选中',
  recoverAll: '恢复全部',
  deleteSelection: '删除选中',
  deleteAll: '删除全部',
  recoverDialog: '恢复窗口',
  recoverTips: `注意：此操作将仅恢复选中窗口至以下分组，若无分组，请前往 <u class='cursor-pointer text-primary' style="text-decoration: none; border-bottom: 1px solid">分组管理</u> 进行创建！`,
  createdName: '归属',
  updateBy: '操作用户',
  deleteTime: '删除时间',
  deleteConfirm: '<p class="text-red">窗口删除后不可恢复，确认要删除已选择的窗口吗？</p>',
  deleteSuccess: '删除成功',
  clearConfirm: '<p class="text-red">窗口删除后不可恢复，确认要删除全部窗口吗？</p>',
  recoverSuccess: '恢复成功',
  browserName: '名称',
  browserSeq: '序号',
  browserSeqPlaceholder: '输入序号搜索',
  browserSeqToolTip: '多个序号使用逗号分隔，序号区间只能从小到大输入，使用 - 分隔（例如：1-100）'
}
