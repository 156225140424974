export default {
  buyMonthComputing: 'Купить',
  buyComputing: 'Купить',
  batchDelete: 'Массовое удаление вычислительной мощности',
  editNameWithSeq: 'Изменить имя вычислительной мощности',
  name: 'Имя',
  computingName: 'Имя',
  remark: 'Примечание',
  renewalComputing: 'Продление',
  useEnvironment: 'Среда',
  deleteTip:
    'Вычислительная мощность не может быть восстановлена после удаления. Вы уверены, что хотите окончательно удалить вычислительную мощность?',
  editNameSuccess: 'Имя вычислительной мощности успешно изменено',
  enableAutoRenew:
    'Вы уверены, что хотите включить Автопродление? <p class="q-mt-sm">После включения aвтопродление система будет автоматически проверять: за 3 дня до истечения срока действия вашей вычислительной мощности, если на вашем счете будет достаточно средств, она автоматически продлит вычислительную мощность на 1 месяц и спишет соответствующую сумму!</p>',
  disableAutoRenew:
    'Вы уверены, что хотите отключить Автопродление? <p class="q-mt-sm">После отключения aвтопродление система перестанет проверять, истекает ли срок действия вычислительной мощности, и не будет автоматически продлевать вычислительную мощность для вас!</p>',
  selectComputing: 'выбор мощности',
  selectComputingType: 'Пожалуйста, выберите тип вычислительных мощностей.',
  delete: 'Удалить',
  renewPhoneTip:
    'Внимание: Пожалуйста, завершите оплату как можно скорее. Убедитесь, что выбранная вычислительная мощность, количество, длительность продления и способ оплаты верны. Заказы не подлежат отмене или обмену!',
  changeTip:
    'Внимание: Пожалуйста, завершите оплату как можно скорее, чтобы убедиться, что выбранный тип вычислительных мощностей, модель устройства, продолжительность, количество и способ оплаты указаны правильно. После размещения заказа возврату и обмену не подлежит!',
  buySuccess: 'Купить успешна.',
  expired: 'Просрочено',
  type: 'тип',
  copyComputingId: 'Копировать Tип ID'
}
