import { getGlobalProxy } from 'api/settings'
import { LocalStorage } from 'quasar'

export const DYNAMICPROXY = [
  'iphtmlauto',
  'oxylabsauto',
  'lumauto',
  'ipideaauto',
  'ip2world',
  'lunaproxy',
  '922proxy',
  'ipfoxy',
  'rolaip',
  'socks5io',
  'skyip',
  'kookeey',
  'ipfly',
  'smart',
  'storm',
  'swift'
]
export const NORMALPROXY = ['http', 'https', 'socks5', 'ssh', 'asocks']
import md5 from 'blueimp-md5'

export const PROXYCONFIG = {
  //   用户名规则: 假设用户名为rolauser，指定美国、纽约州、纽约市
  // rolauser_xxx-country-us-state-ny-city-newyork
  // 参数说明:
  // rolauser_xxx 代表一个子账号（每个子账号代表一个IP），通过修改xxx来修改IP ,xxx可以由字母和数字组合
  rolaip: {
    country: 'country',
    province: 'state',
    city: 'city',
    proxyType: 'socks5'
  },
  // 用户名规则: 假设用户名为时s5user，指定美国、纽约州、纽约市
  // s5user_xxx-country-us-state-ny-city-newyork
  // 参数说明:
  // s5user_xxx代表一个子账号（每个子账号代表一个IP），通过修改xxx来修改IP ,xxx可以由字母和数字组合
  // country  国家代码
  // state 州代码
  // city 城市代码
  socks5io: {
    country: 'country',
    province: 'state',
    proxyType: 'socks5',
    city: 'city'
  },
  //   用户名规则: 假设用户名为时skuser，指定美国、纽约州、纽约市
  // skuser_xxx-country-us-state-ny-city-newyork
  // 参数说明:
  // skuser_xxx代表一个子账号（每个子账号代表一个IP），通过修改xxx来修改IP ,xxx可以由字母和数字组合
  // country  国家代码
  // state 州代码
  // city 城市代码
  skyip: {
    country: 'country',
    province: 'state',
    city: 'city',
    proxyType: 'socks5'
  },
  // https://www.lunaproxy.com/hk/ 账号没钱测试 socks5
  // user-xxxx-region-us-st-california-city-paris-sessid-us2y0o9v68amx93meu-sesstime-10
  lunaproxy: {
    country: 'region',
    province: 'st',
    city: 'city',
    proxyType: 'http',
    sessionId: 'sessid',
    sessionTime: 'sesstime'
  },
  // https://iphtml.com/helper/index.html
  // USERNAME-country-US-state-Ca:PASSWORD
  // country-US-city-los_angeles
  iphtmlauto: {
    country: 'country',
    province: 'state',
    city: 'city',
    proxyType: 'http',
    sessionId: 'session',
    UpperCase: true
  },
  // https://developers.oxylabs.io/proxies/residential-proxies/select-city
  // customer-USERNAME-cc-DE-city-munich
  // customer-HJ006-cc-US-st-us_texas
  oxylabsauto: {
    country: 'cc',
    city: 'city',
    province: 'st',
    prefix: 'customer-',
    proxyType: 'socks5',
    UpperCase: true
  },
  // https://help.brightdata.com/hc/en-us/articles/4413167165969-Using-the-system?_gl=1*bwq02a*_ga*MTkyODA5MzE3Ny4xNjc3ODMzNTE1*_ga_KQX3XWKR2T*MTY3ODA2NTg3MC40LjEuMTY3ODA2Nzc1Ni41MC4wLjA.
  // lum-customer-{YOUR_CUSTOMER_ID}-zone-{YOUR_ZONE}-country-us-state-ny-city-newyork zone
  // 支持http和https
  lumauto: {
    country: 'country',
    city: 'city',
    proxyType: 'http',
    province: 'state'
  },
  //  http://new.ipidea.net/ucenter
  // username-zone-custom-region-us-st-iowa-city-desmoines
  ipideaauto: {
    country: 'zone-custom-region',
    province: 'st',
    city: 'city',
    proxyType: 'socks5',
    ip: true
  },
  // username-zone-resi-region-us-st-california-city-atlanta   类型ip2world
  ip2world: {
    country: 'region',
    province: 'st',
    city: 'city',
    proxyType: 'socks5'
  },
  // https://www.922proxy.com/zh-tw/account_extract.html
  // 922proxy 922s5.proxys5.net:6300:60466649-zone-custom-region-US-sessid-goLmGru7:123456
  '922proxy': {
    country: 'region',
    province: 'state',
    city: 'city',
    proxyType: 'http',
    UpperCase: true,
    sessionId: 'sessid'
  },
  // customer-username-cc-US-st-LA-city-city-sessid-32位
  ipfoxy: {
    country: 'cc',
    province: 'st',
    city: 'city',
    sessionId: 'sessid',
    proxyType: 'socks5',
    UpperCase: true
  },
  // https://www.kookeey.com/   mrliu@bitbrowser.cn  Link2020
  // proxyUserName  9613518-6ec1fcc8-690a4e74daedc44189eb34c99dc7c39c
  // 9047624b-GB-32位 国家是密码后面拼接
  kookeey: {
    proxyType: 'socks5',
    passwordSuffix: true,
    UpperCase: true
  },
  // https://console.ipfly.net/proxy/api  bryaning.me@gmail.com 密码：Bitnet2024
  // bit_test1-zone-resi-region-fr-st-centre-city-orléans:111111:py.ipflygates.com:16666
  ipfly: {
    country: 'zone-resi-region',
    province: 'st',
    city: 'city',
    proxyType: 'socks5'
  },
  // flt123_area-US_city-sanfrancisco:123456@proxy.smartproxycn.com:1000
  smart: {
    country: 'area',
    province: 'state',
    city: 'city',
    UpperCase: true,
    proxyType: 'socks5',
    connect: '_' // 别的代理都是用-链接，这里用_链接不同字段
  },
  // storm-a1521043_area-US_state-Arkansas  storm-a1521043_area-US_city-Bessemer
  storm: {
    country: 'area',
    province: 'state',
    city: 'city',
    UpperCase: true,
    proxyType: 'socks5',
    connect: '_' // 别的代理都是用-链接，这里用_链接不同字段
  },
  // testuser12_custom_zone_AO_st_Benguela
  swift: {
    country: 'custom_zone',
    UpperCase: true,
    province: 'st',
    proxyType: 'socks5',
    joinArea: 'GLOBAL',
    city: 'city',
    connect: '_', // 别的代理都是用-链接，这里用_链接不同字段
    keyValueJoin: '_'
  }
}
async function setGlobalProxy(config) {
  const res = await getGlobalProxy({ proxyType: config.proxyType })
  if (!Object.keys(res).length)
    throw new Error(LocalStorage.getItem('locale') === 'en' ? 'Please complete your global proxy configuration.' : '请完善全局代理设置')
  config.host = res.host
  config.port = res.port
  config.proxyUserName = res.userName
  config.proxyPassword = res.password
  config.proxyAgreementType = res.proxyAgreementType
}
// 国家后面的字符要全部删掉
function proxyNameSplit(proxyName = '', split) {
  let rep = new RegExp(`${split}.*`, 'g')
  return proxyName.replace(rep, '')
}
// 如果页面设置了国家，采用页面用户设置的国家城市
function montageInfo(config, browserId = '') {
  let { country, province, proxyType, city, UpperCase, sessionId, sessionTime, prefix, passwordSuffix, connect, keyValueJoin, joinArea } =
    PROXYCONFIG[config.proxyType]
  if (!connect) {
    connect = '-'
  }
  if (!keyValueJoin) {
    keyValueJoin = '-'
  }
  // 有 sessionTim，则缓存起来，必须首先处理，否则有可能被下方的country处理给干掉
  let sesstime = ''
  if (config.proxyUserName.includes(`-${sessionTime}-`)) {
    const time = config.proxyUserName.match(/-sesstime-(\d+)/)[1]
    sesstime = `-${sessionTime}-${time}`

    config.proxyUserName = config.proxyUserName.replace(sesstime, '')
  }
  // 如果要自动拼接区域，并且用户名里面没有带
  if (joinArea) {
    if (config.proxyUserName.includes(country) && config.country) {
      config.proxyUserName = proxyNameSplit(config.proxyUserName, connect + country)
    } else if (!config.proxyUserName.includes(country) && !config.country) {
      config.proxyUserName = config.proxyUserName + connect + country + keyValueJoin + joinArea
    }
  }
  if (config.country && !passwordSuffix) {
    // config.proxyUserName = proxyNameSplit(config.proxyUserName, country)
    // 有的动态代理需要小写国家
    if (!UpperCase) {
      config.country = config.country.toLowerCase()
    }
    config.proxyUserName += `${connect}${country}${keyValueJoin}` + config.country
  }
  // 有的动态代理没有省份
  if (province && config.province) {
    //  有的代理需要国家_省份拼接
    if (config.proxyType === 'oxylabsauto') {
      config.proxyUserName += `${connect}${province}${keyValueJoin}` + config.country.toLowerCase() + '_' + config.province
    } else {
      config.proxyUserName += `${connect}${province}${keyValueJoin}` + config.province
    }
  }
  // 有的动态代理么有城市
  if (city && config.city) config.proxyUserName += `${connect}${city}${keyValueJoin}` + config.city

  // 如果有sessionId，则生成一个Hash值给它
  if (sessionId) {
    // 先去掉已有的
    const tempArr = config.proxyUserName.split('-')
    const index = tempArr.findIndex(item => item === sessionId)
    if (index > -1) {
      tempArr.splice(index, 2)
    }

    // 再次拼起来
    config.proxyUserName = tempArr.join('-')

    config.proxyUserName += `${connect}${sessionId}-` + md5(config.proxyUserName + browserId)
    if (sesstime) config.proxyUserName += sesstime
  }

  // 去除空格
  config.proxyUserName = config.proxyUserName.replace(/\s/g, '')
  if (prefix && !config.proxyUserName.startsWith(prefix)) {
    config.proxyUserName = prefix + config.proxyUserName
  }

  // 密码
  if (passwordSuffix) {
    let ps = config.proxyPassword.split('-')
    if (config.country) {
      let country = config.country
      if (UpperCase) {
        country = config.country.toUpperCase()
      }
      ps = ps[0] + '-' + country
    } else {
      country = ps[1] ? '-' + ps[1] : ''
      ps = ps[0] + country
    }
    config.proxyPassword = ps + `-${md5(ps)}`
  }
  // 使用socks5代理模式
  if (config.proxyAgreementType) {
    config.proxyType = config.proxyAgreementType
  } else {
    config.proxyType = proxyType
  }
  return config
}

export async function dealwithDymanicProxy(config, browserId) {
  if (config.proxyMethod !== 2) return config
  if (PROXYCONFIG[config.proxyType]) {
    if (config.isGlobalProxyInfo) {
      await setGlobalProxy(config)
    }
    config = montageInfo(config, browserId)
  }
  return config
}

// 处理仅仅是广告位的代理，把 proxyType 改回socks5
export function dealwithAdsProxy(config) {
  const socks5List = ['asocks']
  if (socks5List.includes(config.proxyType)) {
    config.proxyType = 'socks5'
  }
  return config
}
