export default {
  sysDialog: 'Системное сообщение',
  forbiddenMobile:
    'Запрещено входить через мобильный веб-терминал. Используйте клиентское приложение или войдите через компьютерный браузер!',
  refresh:
    'Обновите страницу. Если страница по-прежнему отображается в виде белого экрана, нажмите {shortKey}, чтобы обновить страницу снова!',
  noticeNavBar: 'Уведомление:',
  syncError: 'Ошибка синхронизации',
  upgradeAvailable: 'Обнаружена новая версия',
  onlineService: 'Онлайн-служба поддержки',
  wechatGroup: 'Cвязь',
  wechatGroupTips: 'Нажмите здесь, чтобы присоединиться к официальной группе для общения (QR-код)',
  iKnow: 'Понятно',
  officialWebsite: 'Официальный сайт',
  profile: 'Личный кабинет',
  settings: 'Настройки системы',
  signOut: 'Выйти',
  logAndClose: 'Выйти и закрыть ',
  wechatGroupDialog: 'QR-код официальной группы для общения',
  joinGroup: '(Нажмите, чтобы присоединиться)',
  signOutConfirm:
    'Вы уверены, что хотите выйти из этой учетной записи?<p class="q-mt-sm">Перед выходом из системы рекомендуется закрыть все открытые окна браузера. В противном случае не закрытые окна будут закрыты автоматически, и данные не будут синхронизированы!</p>',
  signOutConfirmClose:
    'Выйти и закрыть?<p class="q-mt-sm">Перед выходом из системы рекомендуется закрыть все открытые окна браузера. В противном случае не закрытые окна будут закрыты автоматически, и данные не будут синхронизированы!</p>',
  signOutWeb: 'Вы уверены, что хотите выйти из этой учетной записи?',
  signOutSuccess: 'Вы успешно вышли из системы',
  pageRecords: '{total} записей',
  gotoPage: 'Перейти к',
  page: 'Стр.',
  '10PerPage': '10 записей/стр',
  '20PerPage': '20 записей/стр',
  '50PerPage': '50 записей/стр',
  '100PerPage': '100 записей/стр',
  noData: 'Нет данных',
  reminder: 'Напоминание',
  reminderComments:
    'BitBrowser является бесплатным на протяжении более года. В условиях постоянных повторных вспышек эпидемии, непрерывной изоляции и отсутствия доходов компании, стало трудно справляться с расходами на персонал и серверы. Мы вынуждены начать взимать плату, чтобы продолжать обслуживание и улучшение продукта. После начала приемки платежей услуги будут поддерживаться постоянно без вероятности их прерывания! Мы  надеемся на ваше неизменное понимание и поддержку!! (Чтобы подчеркнуть намерение предоставления долгосрочных услуг нашим пользователям, мы установилиплатежные расценки на низком уровне, на данный момент они составляют 1/4-1/8 от рыночной цены)',
  reminderTips: 'Примечание:',
  reminder1:
    '1. Для пользователей с неистекшим сроком действия пакета начало взимания платы наступит после истечения срока действия пакета.',
  reminder2:
    '2. Вы можете изменить пакет на более низкий уровень, который подходит вам. При переходе на более низкий пакет плата не взимается, и стоимость продления будет ниже. Вы можете уменьшить количество сотрудников или использовать количество сотрудников, включенное в пакет, чтобы уменьшить стоимость продления!!!',
  reminder3:
    'В знак поддержки старых клиентов все старые клиенты могут получить купон на скидку 20%, нажав кнопку "Получить купон на скидку 20% для старых клиентов" на главной странице!',
  dismiss: 'Больше не показывать',
  imgLoadingError: 'Ошибка загрузки изображения',
  scanWechat: 'Отсканируйте QR-код WeChat для связи с обслуживанием клиентов',
  paymentMoney: 'Сумма к оплате',
  cny: '({money} юаней)',
  moneyUnit: '¥',
  waitingForPay: 'Ожидание завершения оплаты...',
  syncDialog: 'Устранение ошибок синхронизации данных',
  syncTips:
    'Внимание: из-за проблем сети и других причин эти окна могут закрыться с неудачной синхронизацией данных! Рекомендуется изменить или отключить прокси-узел и нажать кнопку "Повторить" для повторной синхронизации! (Неудачная синхронизация может вызвать проблемы с выходом из учетной записи и т. д.)',
  syncTips2: 'Нажмите "Игнорировать все", чтобы игнорировать все неудачные синхронизации данных и больше не синхронизировать их!',
  syncIgnore: 'Игнорировать все',
  usdtMoney: 'Сумма перевода: ',
  usdtValue: '({money} юаней, курс: {rate})',
  usdtAddress: 'Адрес получателя',
  taxIDPlaceholder: 'После завершения перевода, введите полный номер транзакции (TxID, т. е. Transaction ID)',
  taxID: 'Номер транзакции (TxID)',
  usdtTips: 'Примечание:',
  usdtTips1:
    '1. Просканируйте QR-код для оплаты или используйте "Адрес получателя" для перевода, обязательно введите точную сумму перевода, указанную в "Сумма перевода" с точностью до десятичной точки, иначе перевод не будет выполнен! Примечание:',
  usdtTips11:
    'Если в процессе перевода взимается комиссия за перевод USDT, убедитесь, что фактическая сумма получения соответствует "Сумме перевода на этой странице".',
  usdtTips2:
    '2. После завершения перевода дождитесь подтверждения на цепи (примерно несколько минут), затем введите точный номер транзакции (TxID) этого заказа и нажмите "Оплата завершена"!',
  taxIDRule: 'Введите TaxID',
  wechatScanPay: 'Оплатите, просканировав QR-код в WeChat',
  quitConfirm:
    'Вы уверены, что хотите выйти из программы? <p class="q-mt-sm">Перед выходом рекомендуется вручную закрыть все открытые окна браузера, иначе не закрытые окна будут закрыты автоматически, что может привести к неудачной синхронизации данных!</p>',
  changeLineConfirm:
    'Вы уверены, что хотите сменить линию?<br/> При смене линии будут закрыты все браузеры и вам придется заново войти в клиентское приложение!',
  clientVersion: 'Версия: ',
  releaseNote: 'Журнал обновлений: ',
  downloadBackgroud: 'Фоновая загрузка',
  upgradeNow: 'Обновить сейчас',
  upgradeTips: 'При установке обновления будут закрыты открытые браузеры. Обратите внимание на сохранение данных!',
  install: 'Установить',
  applyingUpdates: 'Применение обновлений, пожалуйста, подождите',
  updateError:
    'Ошибка обновления. Перезагрузите клиентское приложение и попробуйте обновиться снова в режиме администратора.<br/>Примечание: После успешного обновления не запускайте клиентское приложение от имени администратора, иначе некоторые функции могут не работать!',
  uncompressError: 'Ошибка распаковки файла обновления',
  updateDetected: 'Обнаружено новое обновление. Страница будет обновлена для применения последней версии.',
  首页: 'Главная',
  line: 'Линия',
  goodMorning: 'Доброе утро,',
  goodMorning2: 'Доброе утро,',
  goodAfternoon: 'Добрый день,',
  goodAfternoon2: 'Добрый день,',
  GoodEvening: 'Добрый вечер,',
  loginTo: 'Добро пожаловать в {arg}',
  usdtAddressTips:
    'Внимание: при переводе тщательно проверьте адрес получателя платежа, совпадает ли он с адресом, скопированным в буфер обмена, чтобы избежать потерь от злонамеренных действий вредоносных программ.',
  whatsappService: 'Нажмите, чтобы добавить обслуживание в WhatsApp',
  telegramService: 'Нажмите, чтобы добавить обслуживание в Telegram',
  error400: 'Неправильное имя пользователя или пароль',
  changeLocaleTips:
    'При изменении языка страница будет обновлена. Если страница по-прежнему отображается в виде пустого экрана, нажмите {shortKey}, чтобы обновить страницу!',
  numUnit: 'шт.',
  toDownlaod: 'Скачать сейчас',
  oldWindowsTips:
    'win7/win8/win8.1/windows server 2012 больше не поддерживают текущее ядро. Переключитесь на 104-е ядро для использования.',
  quit: 'Выход из программы',
  popTip: 'Всплывающее уведомление',
  securityTitle: 'Советы по безопасности данных',
  securityContent:
    'В последней версии BitBrowser полностью обновлена стратегия шифрования, выполняется отдельная изоляция ключей для данных каждого окна, максимально обеспечивая безопасность данных пользователя. <span style="text-decoration:underline">Из-за отсутствия способности версий 5.0.8 и ниже расшифровать последние зашифрованные данные, некоторые функции синхронизации старых версий были отключены. Мы настоятельно рекомендуем всем пользователям немедленно обновить до последней версии, чтобы повысить защиту данных и использовать более безопасные функции синхронизации!</span>',
  securityContent2:
    'Для пользователей, беспокоящихся о Web3.0 и безопасности своих данных, мы рекомендуем принимать меры, такие как включение двухфакторной аутентификации при входе в BitBrowser, изменение паролей на веб-сайтах и перевод активов кошелька для повышения безопасности.',
  securityContent3:
    "Дружественное напоминание: Для пользователей Web3.0 и цифровых кошельков, независимо от используемого браузера, не рекомендуется включать опцию 'синхронизация данных расширения'. Мы рекомендуем хранить данные расширения приложения локально для максимальной безопасности!! Также удалите облачные данные расширения, чтобы уменьшить неизвестные риски безопасности!!! (Гарантия безопасности  BitBrowser: в настоящее время и в будущем, при создании нового окна для импорта нового кошелька без включения синхронизации данных расширения, нет никаких рисков для безопасности).",
  chromeUpdateDetected: 'Обнаружено обновление ядра, нажмите, чтобы просмотреть подробности',
  chromeUpdateTitle: 'Обнаружено обновление ядра',
  chromeVersionUpdate: 'Обнаружено обновление ядра {version}, нажмите, чтобы обновить сейчас',
  chromeUpdateError: 'Обновление не удалось: {msg}',
  chromeUpdateSuccess: 'Обновление ядра {version} успешно выполнено.',
  ignoreUpdate: 'Проигнорировать это обновление',
  themeDarkMode: 'Светлая модель / Темная модель',
  copyPaymentUrl: 'Скопируйте ссылку на оплату и откройте её в браузере Chrome, чтобы совершить платёж.',
  alipayQRTips: 'Пожалуйста, используйте Alipay для сканирования и оплаты.'
}
