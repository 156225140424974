export default {
  add: 'Добавить сотрудников',
  users: 'Сотрудники',
  assigned: 'Использовано',
  total: 'Всего',
  role: 'Роль:',
  roleHead: 'Роль',
  usernameHead: 'Имя пользователя',
  username: 'Имя пользователя:',
  showBrowsers: 'Просмотр данных окон сотрудника',
  resetPass: 'Сбросить пароль пользователя',
  resetPlaceholder: 'Введите пароль',
  resetPassword: 'Пароль',
  confirmPasswordPlaceholder: 'Подтвердите пароль',
  confirmPassword: 'Подтвердить пароль',
  rolePlaceholder: 'Выбор роли (Быстрое создание правым нажатием)',
  namePlaceholder: 'Введите фамилию и имя',
  name: 'Фамилия и имя',
  usernamePlaceholder: 'Введите имя пользователя для входа',
  passwordPlaceholder: 'Введите пароль для входа',
  password: 'Пароль для входа',
  tel: 'Номер телефона',
  telPlaceholder: 'Введите номер телефона',
  emailPlaceholder: 'Введите адрес электронной почты',
  browser: '(браузера)',
  phone: '(телефона)',
  email: 'Эл. почта',
  userType: 'Статус',
  userType2Tips: 'Можно управлять всеми учетными записями сотрудников и данными окон сотрудников, а также групповыми данными',
  userType3Tips: 'Можно управлять учетными записями подчиненных сотрудников и данными окон сотрудников, а также групповыми данными ',
  userType4Tips: 'Нет прав на управление, можно только управлять данными подчиненных окон',
  members: 'Управление участниками',
  manager: 'Менеджер',
  noData: 'Нет данных',
  group: 'Уполномоченная группа',
  belong: 'Принадлежность: ',
  browserCount: 'Количество окон:',
  browserCountHint: 'Только главная учетная запись может указывать количество окон для размещения',
  browserCountLabel: 'Создаваемые профили браузера',
  envCountLabel: 'Создаваемые профили телефона',
  groupDialogBtn: 'Перейти к просмотру',
  userGroup: 'Группа сотрудников',
  userGroupTips:
    'Примечание: При выборе Группы сотрудников, нажатие кнопки Перейти к просмотру приведет к автоматическому переходу на страницу окон браузера и автоматическому фильтрованию данных окон, относящихся к группе, указанной вами.',
  browserCountTableHead: 'Профили браузера',
  devCountTableHead: 'Профили телефона',
  countColumnTips: 'Используемые профили / Всего профилей',
  status: 'Статус',
  createdTime: 'Дата создания',
  passwordRuleMsg1: 'Введите пароль',
  passwordRuleMsg2: 'Пароль должен содержать не менее 6 символов',
  passwordRuleMsg3: 'Пароль не должен превышать 30 символов',
  confirmPasswordMsg1: 'Подтвердите пароль',
  confirmPasswordMsg2: 'Пароли не совпадают',
  addDialogTitle: 'Добавить сотрудника',
  editDialogTitle: 'Изменить сотрудника',
  roleRuleMsg: 'Выберите роль',
  usernameRuleMsg: 'Имя пользователя должно содержать не менее 6 символов',
  emailRuleMsg: 'Введите правильный адрес электронной почты',
  emailRuleMsg2: 'Адрес электронной почты не должен превышать 50 символов',
  browserCountRuleMsg: 'Введите количество окон, которые можно создать',
  browserCountRuleMsg2: 'Минимальное количество окон не может быть меньше 0',
  browserCountRuleMsg3: 'Количество создаваемых окон не может превышать общее количество доступных окон.',
  envCountRuleMsg: 'Пожалуйста, введите количество создаваемых сред',
  envCountRuleMsg2: 'Минимальное количество сред не может быть меньше 0',
  envCountRuleMsg3: 'Количество создаваемых сред не может превышать общее количество доступных сред.',
  deleteConfirm:
    'Вы уверены, что хотите удалить этого пользователя?<br/><span class="text-negative">Когда у подаккаунта есть привязанные окна, пользователь не может быть удален напрямую. Пожалуйста, сначала удалите или переместите окна, принадлежащие пользователю, а затем удалите подаккаунт!</span>',
  deleteSuccess: 'Пользователь успешно удален',
  deleteUser: 'Удалить пользователя',
  resetSuccess: 'Пароль пользователя успешно сброшен',
  activateConfirm: 'Вы уверены, что хотите активировать этого <br/> пользователя?',
  deactivateConfirm: 'Вы уверены, что хотите деактивировать этого <br/> пользователя?',
  activateSuccess: 'Пользователь успешно активирован',
  deactivateSuccess: 'Пользователь успешно деактивирован',
  editSuccess: 'Пользователь успешно изменен',
  addSuccess: 'Пользователь успешно добавлен',
  allBrowsers: 'Все окна',
  ownBrowsers: 'Собственные окна',
  userGroupDialogTitle: 'Просмотр данных окон сотрудника ({name})',
  roles: 'Управление ролями',
  addRole: 'Добавить роль',
  roleNamePlaceholder: 'Введите имя роли',
  roleName: 'Имя роли',
  permission: 'Права доступа',
  roleNameRule1: 'Введите имя роли',
  roleNameRule2: 'Имя роли не должно превышать 8 символов',
  roleNameRule3: 'Введите имя роли на английском и китайском языках, или цифрами',
  editRoleTitle: 'Изменить роль',
  deleteRoleTitle: 'Удалить роль',
  addRoleTitle: 'Добавить роль',
  deleteRoleConfirm:
    'Вы уверены, что хотите удалить эту роль?<br/>После удаления роли ее нельзя будет восстановить. <br/>Пожалуйста, будьте осторожны!',
  deleteRoleSuccess: 'Роль успешно удалена',
  editRoleSuccess: 'Роль успешно изменена',
  addRoleSuccess: 'Роль успешно добавлена',
  checkPermission: 'Выберите права доступа',
  toBrowsers: 'Перейти к просмотру',
  groupHint: 'Одна и та же группа может быть авторизована для нескольких учетных записей сотрудников',
  secondaryPasswordMsg: 'Введите пароль, соответствующий этой учетной записи, для выполнения этой операции!',
  securityVerification: 'Выполните проверку безопасности',
  securityVerification1: 'Переместите вправо, чтобы заполнить пазл',
  securityVerification2: 'Попробуйте еще раз',
  closeDualVerify: 'Включить/отключить двойную проверку',
  closeDualVerifyDialog: 'Вы уверены, что хотите отключить двойную проверку для этого сотрудника?',
  openDualVerifyDialog: 'Вы уверены, что хотите включить двойную проверку <br /> для этого сотрудника?',
  openSuccess: 'Двойная проверка успешно включена',
  closeSuccess: 'Двойная проверка успешно отключена',
  oneClick: 'Быстро сгенерировать',
  usernameRequired: 'Введите имя пользователя',
  usernameLenLimit: 'Имя пользователя не должно превышать 30 символов.',
  usernameSchema: 'Имя пользователя должно содержать английские буквы и цифры, или нижнее подчеркивание',
  dashboardRoleTip:
    'Независимо от того, выбрано это или нет, панель управления по умолчанию будет отображаться для всех пользователей. Подразделы прав доступа на панели управления контролируют только отображение функциональных модулей на панели управления.'
}
