export default {
  inviteUrl: '您的推广链接',
  qrCodeName: '{name}推广二维码',
  generateQrcode: '生成二维码',
  generateQrcodeTip: '使用您的推广链接生成的二维码，推荐用户使用产品以获取奖励。',
  inviteUrlTips: '『请您先开通套餐，即可永久展示您的推广链接』',
  copyUrl: '复制链接',
  inviteCode: '您的邀请码',
  inviteTips: '『成为推广者后展示』',
  copyCode: '复制邀请码',
  overview: '数据概览',
  couponBtn: '查看优惠券',
  withdraw: '提现',
  detailsBtn: '推广详情',
  withdrawRecords: '提现记录',
  inviteUsers: '邀请总用户数',
  userUnit: '人',
  rewards: '累计分成金额',
  moneyUnit: '元',
  withdrawTotal: '累计提现',
  withdrawMoney: '可提现',
  policyText1: '{name}邀请奖励机制上线啦，重金奖励出击！',
  policyText2: '通过邀请奖励机制，您不仅可以将{name}推荐给有需求的人，同时可以为自己带来丰厚的分成回报！',
  policyTitle: '推广奖励规则：',
  policy1: '1. 针对所有{name}付费用户开放！',
  policy2: '2. 复制【您的推广链接】，使用您擅长的方式推广，通过您的推广链接注册用户并消费，您可以获得消费金额的高比例分成。',
  policy3Label: '推广奖励：',
  policy3: '用户浏览器套餐消费金额的 {rate}%分成，手机环境套餐消费金额的 {rate1}%分成！',
  policy3Tips: '（永久被动收入，不限定期限）',
  policy4Label: '额外奖励：',
  policy4: '邀请总计5个用户，赠送50元优惠券；邀请总计10个用户，再赠送50元优惠券；邀请总计20个用户，再赠送100元优惠券。',
  policy51: '可提现金额需要大于',
  policy52: '，同时至少',
  policy53: '个邀请用户购买过套餐，即可申请提现。',
  policy6: '如在奖励期内您的用户取消订阅或产生退款，对应的邀请奖励也随即取消。',
  policy7: '不得以任何不正当手段/舞弊行为参与本活动，如存在违规行为，官方将冻结全部奖励并追究法律责任。',
  policy7_1: '相同的设备/IP/支付账号产生关联的下级用户，永久停止对上级分佣！',
  policy8: '本活动最终解释权归主办方所有，如有疑问请联系客服！',
  policy9: '注：相同的设备/IP/支付账号产生关联的下级用户，永久停止对上级分佣！',
  copySuccess: '复制成功',
  withdrawMsg: `可提现金额需要大于等于 <span class='text-red'>{symbol}{money}</span> ！`,
  withdrawMsg2: `至少需要 <span class='text-red'>{min}</span> 个直接下线用户购买过付费套餐，目前仅有 <span class='text-red'>{max}</span> 个！`,
  detailsDialog: '推广详情',
  detailTips: '您是总代理，可以设置下级用户分成比例！',
  rewardsTips: '您的分成比例为：下级套餐消费{rate}%，云手机消费{rate1}%，您下级用户直接产生消费您将获得对应的分成！',
  rewardsTips2:
    '您的下级用户再去发展下级用户，他将获得佣金分成比例为您所设定的分成比例，同时您将获得：消费金额 ×（您的分成比例 - 下级用户分成比例）的返佣！',
  users: '下级用户',
  records: '分成记录',
  invitedUsers: '邀请总用户数：',
  totalPay: '累计消费金额：',
  totalRewards: '累计分成金额：',
  createdTimeStart: '注册时间起始日',
  createdTimeEnd: '注册时间终止日',
  tradeTimeStart: '交易时间起始日',
  pleaseTradeTime: '请选择交易时间',
  pleaseCreatedTime: '请选择注册时间',
  tradeTimeEnd: '交易时间结束日',
  minRate: '不可低于系统默认比例',
  maxRate: '不可高于您的分成比例',
  username: '用户名',
  invitedBy: '隶属于',
  rewardsRate: '窗口分成比例',
  phoneRewardsRate: '云手机分成比例',
  totalPayMoney: '累计消费金额',
  createdTime: '注册时间',
  remark: '消费项目',
  payMoney: '消费金额',
  percent: '分成比例',
  reward: '分成金额',
  tradeTime: '交易时间',
  saveSuccess: '修改成功',
  status0: '审核中',
  status1: '已打款',
  status2: '已拒绝',
  alipay: '支付宝：',
  bank: '银行卡：',
  paypal: 'PayPal：',
  balance: '费用中心余额',
  requestTime: '申请时间',
  requestMoney: '提现金额',
  payInfo: '收款信息',
  status: '状态'
}
