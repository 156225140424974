import { request } from 'src/boot/axios'

// 手机列表
export function getCloudPhoneList(data) {
  return request({ url: '/v1/cloudphone/cloudPhonesEvn/list', method: 'post', data })
}

/**
 * @description 修改手机名称
 * @param {object} data
 * @param {number} data.id
 * @param {string} data.name
 * */
export function updateCloudPhoneName(data) {
  return request({ url: '/v1/cloudphone/cloudPhonesEvn/updateName', method: 'post', data })
}

// 批量修改备注
export function updateCloudPhoneRemark(data) {
  return request({ url: '/v1/cloudphone/cloudPhonesEvn/batchUpdateRemark', method: 'post', data })
}

// 批量修改分组
export function batchUpdateCloudPhoneInfo(data) {
  return request({ url: '/v1/cloudphone/cloudPhonesEvn/batchUpdateData', method: 'post', data: data })
}

// 批量修改代理
export function batchUpdateEnvironmentProxy(data) {
  return request({ url: '/v1/cloudphone/cloudPhonesEvn/batchUpdateProxy', method: 'post', data: data })
}

// 查询环境
export function getEnvironmentDetail(id) {
  return request({ url: '/v1/cloudphone/cloudPhonesEvn/' + id, method: 'get' })
}

// 修改云手机
export function updateCloudPhone(data) {
  return request({ url: '/v1/cloudphone/cloudPhonesEvn', method: 'put', data })
}

// 添加云手机环境
export function addCloudPhone(data) {
  return request({ url: '/v1/cloudphone/cloudPhonesEvn', method: 'post', data })
}

//删除云手机环境
export function delteCloudPhone(data) {
  return request({ url: '/v1/cloudphone/cloudPhonesEvn ', method: 'delete', data })
}

// 修改序号
export function updateSort(data) {
  return request({ url: '/v1/cloudphone/cloudPhonesEvn/updateSort', method: 'post', data })
}

// 设为常用
export function setMostCommon(data) {
  return request({ url: '/v1/cloudphone/cloudPhonesEvn/setMostCommon', method: 'post', data })
}

// 获取套餐列表
// 套餐列表
export function getPackageList(data) {
  return request({ url: '/v1/cloudphone/envPackage/envPackageList', method: 'post', data })
}

// 获取开通、变更套餐计算应付金额
export function calPayMoney(data) {
  return request({ url: '/v1/cloudphone/envPackage/calPayMoney', method: 'post', data })
}

// 获取续费套餐计算应付金额
export function calRenewPayMoney(data) {
  return request({ url: '/v1/envPackage/calRewPayMoney', method: 'post', data })
}

// 开通、变更套餐，直接使用余额
export function updatePackageByBlance(data) {
  return request({ url: '/v1/cloudphone/envPackage/commitPackagePay', method: 'post', data })
}

// 续费 直接使用余额
export function renewPackage(data) {
  return request({ url: '/v1/cloudphone/envPackage/renewPackage', method: 'post', data })
}

// 获取开通、变更套餐计算应付金额
export function calRewPayMoney(data) {
  return request({ url: '/v1/cloudphone/envPackage/calRewPayMoney', method: 'post', data })
}

// 获取算力和环境提醒
export function getComputationalPowerAndEnvExpireRemind(data) {
  return request({ url: '/v1/cloudphone/cloudPhonesComputationalPower/getComputationalPowerAndEnvExpireRemind  ', method: 'post', data })
}

// 文件分发，应用安装卸载
export function batchFile(data) {
  return request({ url: '/v1/cloudphone/cloudPhonesFileUploads/batchFiles', method: 'post', data, timeout: 60 * 1000 })
}

// 根据id查询打开手机
export function checkBinding(id) {
  return request({ url: '/v1/cloudphone/cloudPhonesEvn/checkBinding/' + id, method: 'get' })
}

// 关闭状态 释放算力
export function closePhoneStatus(data) {
  return request({ url: '/v1/cloudphone/cloudPhonesEvn/close', method: 'post', data })
}

// 一键新机
export function oneClickNewMachine(id) {
  return request({ url: `v1/cloudphone/cloudPhonesEvn/oneClickNewMachine/${id}`, method: 'get' })
}

// 编辑环境的时候获取算力列表
export function getComputationalPowerTab(data) {
  return request({ url: `v1/cloudphone/cloudPhonesComputationalPower/getComputationalPowerTab`, method: 'post', data })
}

// 设置环境启用禁用
export function enableOrDisable(data) {
  return request({ url: `/v1/cloudphone/cloudPhonesEvn/enableOrDisable`, method: 'post', data })
}
// 验证是否可以购买国内算力
export function authenticationRestrictions(data) {
  return request({ url: `v1/cloudphone/cloudPhonesEvn/authenticationRestrictions`, method: 'post', data })
}
