export default {
  balance: '余额',
  recharge: '在线充值',
  coupon: '查看优惠券',
  bill: '账单月费',
  month: '月',
  days: '天',
  renew: '续费套餐',
  expiredDays: '距离到期还剩',
  expire: '到期',
  freePackage: '免费套餐',
  noExpired: '永久可用',
  billDetail: '账单详情',
  pkgLabel: '经典套餐: 窗口',
  users: '员工',
  additionUsers: '额外员工:',
  activate: '开通套餐',
  change: '变更套餐',
  changeUsers: '变更员工',
  statistics: '访问统计',
  todayOpen: '今日打开次数',
  openCountComment: '每天打开窗口最大次数 = 总窗口数 x {count}，超过总次数当天无法打开，第二天恢复。',
  ordersNotPay: '未支付订单（USDT）',
  moneyUnit: '元',
  unit: '个',
  paymentOrder: '继续支付',
  paymentOrderDialog: '继续支付订单',
  paid: '已完成支付',
  wechatPay: '微信支付',
  balancePay: '余额支付',
  alipay: '支付宝支付',
  rechargePay: '充值',
  withdrawPay: '提现充值',
  time: '时间',
  payMoney: '交易金额',
  payType: '支付方式',
  preferential: '※ 折扣钜惠，活动时间：仅限2023.9.5-2023.10.10，过期后恢复原价！',
  accountBalance: '账户余额',
  payRemark: '交易详情',
  additionErrMsg: '免费套餐不支持变更额外员工，请先开通收费套餐',
  paySuccess: '支付成功',
  changeInfo: '<strong>经典套餐</strong>(原套餐: 浏览器窗口{browserCount}个 员工数{users}个)',
  pkgChangeLabel: '经典套餐',
  pkgChangeLabel2: '原套餐：浏览器窗口',
  pkgChangeLabel3: '个 员工数',
  pkgBrowsers: '浏览器窗口：{count}个',
  pkgUsers: '员工：{count}个',
  pkgUnit: '/月',
  activateMonths: '开通周期',
  useBalance: '使用余额',
  balanceAssets: '可用余额: {balance}元',
  paypalTips: '仅支持海外PayPal账户支付',
  totalBrowsers: '总浏览器窗口数: {count}个',
  pkgExpired: '套餐到期时间：',
  pkgOpenCountTips: '注：该套餐，每天打开窗口最大次数 = {browsers} x {maxCount}，超过总次数当天无法打开，第二天恢复。',
  pkgOpenEnvCountTips: '注：每个手机环境每月最大新机 {maxCount} 次，超过次数当月无法新机，次月恢复。',
  shouldPay: '应付金额：',
  pkgCommentsLabel1: '费用计算公式：',
  pkgCommentsValue1: '(新套餐金额-旧套餐金额)/31*剩余天数',
  pkgCommentsLabel2: '说明：',
  pkgCommentsValue2: '减配套餐系统不会退费，请谨慎操作',
  usersCommentsLabel: '增加个数 x 费用/31 x 剩余天数',
  usersCommentsValue: '减配套餐或者减少员工系统不会退费，请谨慎操作',
  changeConfirm: `
              <p class="text-red text-bold">降低套餐以后：</p>
              <p class="text-red q-mt-sm">1. 将禁用所有员工账号，请到“员工管理”处再次启用！</p>
              <p class="text-red q-mt-sm">2. 系统不进行退费，并且立刻生效！</p>
              <p class="text-red q-mt-sm">3. 套餐数量以外的窗口，不会被删除，但会被禁止打开！</p>
              <p class="q-mt-sm">是否需要操作？</p>
              `,
  environmentChangeConfirm: `降低套餐后的手机环境数小于当前已创建的手机环境数，无法降低套餐，请跟随下方指示禁用超出部分的手机环境数后重试！
  <p class="q-mt-sm text-bold"> 云手机环境 -> 更多操作 -> 批量禁用环境</p>
  `,
  goDisableProfile: '去禁用环境',
  activateSuccess: '套餐开通成功',
  changeSuccess: '套餐变更成功',
  calculateError: '金额计算错误，请重试',
  couponDialog: '优惠券',
  myCoupon: '我的优惠券',
  usedCoupon: '已使用优惠券',
  couponType: '优惠券类型',
  couponStatus: '优惠券状态',
  couponCode: '优惠券码',
  couponRemark: '备注',
  cashCoupon: '现金券',
  discountCoupon: '折扣券',
  couponStatus0: '未使用',
  couponStatus1: '已使用',
  couponValue: '优惠券面值',
  couponValueFormat: '{value} 元',
  couponCreatedTime: '发放日期',
  couponExpired: '有效期',
  couponState: '状态',
  couponExpense: '使用优惠券',
  couponExpensePlaceholder: '请输入优惠券码',
  changeUsersDialog: '变更额外员工',
  additionalUsers: '额外增加员工',
  assignedUsers: '已用',
  totalUsers: '总数',
  increase: '增加',
  reduce: '减少',
  userCost: '费用:',
  userCostUnit: '/个/月',
  extraUsers: '原有额外员工数:',
  extraUsers2: '个，本次',
  reduceTips: '注意：减少员工时，如果剩余的员工数（总数-已用）小于要减少的数量，则变更员工后将禁用所有员工账号，请到员工管理再次启用。',
  totalExtraUsers: '总员工数：',
  usersRule1: '请输入大于等于0的整数',
  usersRule2: '请输入小于10000的整数',
  usersRule3: '减少不能大于原有额外员工数',
  reduceUsersConfirm: '减少额外员工，系统不进行退费，并且立刻生效，请谨慎操作！<br/>是否需要操作？',
  changeUsersSuccess: '额外员工变更成功',
  originalPrice: '原价',
  finalPay: '实付',
  cycleMonth: '个月',
  cycleMonths: '个月',
  confirmActivate: '确认开通',
  confirmChange: '确认变更',
  confirm: '确认',
  orderCreatedTime: '订单时间',
  orderMoney: '订单金额',
  orderState: '订单状态',
  notPay: '未支付',
  hasPaid: '已支付',
  rechargePlaceholder: '请填写金额',
  rechargeAmount: '金额',
  minAmount: '最小充值金额为：{amount}',
  rechargeRule1: '请填写金额，金额为数字',
  rechargeRule2: '最小充值金额为',
  rechargeRule3: '充值金额最小单位为分',
  pkgDetail: '套餐详情',
  extraAddUsers: '额外员工数：{count}个',
  renewCycle: '续费周期',
  youhui: '优惠：',
  renewExpired: '续费后到期时间：',
  renewComments: '(当前套餐费用 + 额外员工费用) x 月数',
  renewEnvironmentComments: '当前套餐费用 x 月数',
  confirmRenew: '确认续费',
  renewSuccess: '续费成功',
  orderType: '订单类型',
  orderType1: '充值',
  orderType2: '开通套餐',
  orderType20: '购买云手机',
  orderType21: '续费云手机',
  orderType22: '包月算力',
  orderType23: '包月算力',
  orderType3: '变更套餐',
  orderType4: '续期套餐',
  orderType8: '变更员工',
  orderType9: '退款充值',
  orderType31: '开通套餐',
  orderType32: '续期套餐',
  orderType33: '变更套餐',
  orderType34: '临时算力',
  orderType34Rmark: '临时算力，金额：{money}',
  orderType22Rmark: '购买算力，{type}，{name}，{num}台，{month}月',
  orderType23Rmark: '续费算力：{name}，金额：{money}，月数：{month}',
  cycle: '周期',
  pkg: '套餐',
  activatePackage: '套餐{pkg}，周期：1个月',
  activatePackageMonths: '套餐{pkg}，周期：{cycle}个月',
  changePlan: '变更前：{before}套餐，变更后：{after}套餐',
  renewDetail: '套餐：{pkg}，周期：{months}个月',
  increaseUsers: '增加员工：{count}个',
  reduceUsers: '减少员工：{count}个',
  autoRenew: '自动续费',
  enableAutoRenew:
    '确认开启自动续费吗？<p class="q-mt-sm">开启自动续费后，系统会自动检测：在您的套餐到期的前3天，若您的账户有充足的余额，会自动续费1个月套餐并扣相应余额！</p>',
  disableAutoRenew: '确认取消自动续费吗？<p class="q-mt-sm">取消自动续费后，系统会停止检测套餐是否到期，且不会为您自动续费套餐！</p>',
  rate: '费率：',
  bankCard: '银行卡',
  ratioRule1: '不可低于系统默认比例',
  ratioRule2: '不可高于您的佣金比例',
  payWarning: `<p class="text-red">注意：请规范使用该支付方式，如发现盗刷等风险支付行为，您的账号将永久封禁，不可解封。</p>
  <p class="text-red q-mt-sm">我们有着严格的风控措施，一旦盗刷CVV，会永久封禁账号且不可找回数据！</p>
  `,
  usedTimes: '已使用{arg}次',
  usedDate: '使用日期',
  orderNum: '订单号',
  remainingTimes: '剩余使用次数',
  Tobepaid: '待支付',
  chinIpbuyTip: 'IP为中国大陆地区，不支持该支付方式！',
  discountCoupons: '该项仅允许使用金额优惠券，禁止使用折扣优惠券！',
  discount: '优惠券优惠：',
  airwallexTip: '银行卡支付仅支持10美元以上金额，请账户充值10美元后使用余额支付。',
  model: '机型配置',
  buyingCycle: '购买周期',
  buyTime: '购买时长',
  buyNum: '购买数量',
  enterBuyNum: '请输入购买数量',
  airwallexTip: '银行卡支付仅支持10美元以上金额，请账户充值10美元后使用余额支付。',
  browserBill: '窗口账单',
  computingBill: '云手机账单',
  phoneComputingPower: '手机算力',
  monthlyComputing: '包月算力：',
  todaynewPhone: '今日一键新机次数',
  todaynewPhoneTip: '每天最大新机次数 = 总环境数 x {num}，超过总次数当天无法新机，第二天恢复',
  monthAdditionalComputing: '当月算力消耗金额：',
  phoneEnvironment: '手机环境',
  environmentNum: '环境数：',
  detail: '详情'
}
