export default {
  dialogTitle: '申请提现',
  balance: '可提现金额：',
  payType: '提现方式',
  payMoney: '提现金额',
  payMoneyPlaceholder: '请输入提现金额',
  quickCheckPlaceholder: '快速选择转账信息',
  quickCheck: '快捷选择',
  alipayPlaceholder: '请输入支付宝账号',
  cardPlaceholder: '请输入转账卡号',
  alipay: '支付宝账号',
  cardNo: '转账卡号',
  paypal: 'PayPal账号',
  bankPlaceholder: '请输入开户行名称',
  bank: '开户行',
  usernamePlaceholder: '请输入姓名',
  bankUsernamePlaceholder: '请输入开户姓名',
  username: '姓名',
  bankUsername: '开户姓名',
  moneyRuleMsg1: '请输入提现金额',
  moneyRuleMsg2: '提现金额必须大于0且最多不超过两位小数',
  moneyRuleMsg3: '不能超过可提现金额',
  alipayRuleMsg1: '请输入支付宝账号',
  alipayRuleMsg2: '支付宝账号为手机号码或者邮箱',
  alipayRuleMsg3: '支付宝账号不能超过50位',
  alipayUsernameRule1: '请输入支付宝账号姓名',
  alipayUsernameRule2: '支付宝账号姓名不能超过30个字',
  cardRule1: '请输入转账卡号或邮箱',
  cardRule2: '请输入合法的转账卡号或邮箱',
  cardRule3: '转账卡号或邮箱长度不可超过50位',
  bankRule1: '请输入开户行名称',
  bankRule2: '开户行名称中不能包含有特殊字符',
  bankRule3: '开户行名称不能超过64个字符',
  bankUsernameRule1: '请输入开户名称',
  bankUsernameRule2: '开户名称中不能包含有特殊字符',
  bankUsernameRule3: '开户名称不能超过64个字符',
  withBalance: '提现到费用中心余额（用于产品开通等消耗）',
  withCard: '提现到银行卡',
  withAlipay: '提现到支付宝',
  withPaypal: '提现到PayPal',
  requestSuccess: '申请提现成功，后台审核中',
  paypalPlaceholder: '请输入PayPal账号',
  withdrawMinMoney: '提现金额需要大于{num}'
}
