export default {
  groupTab: 'Phone Profile Group',
  num: 'Group Profiles',
  creatEnv: 'Add',
  editEnv: 'Edit Profile',
  computingSet: 'Settings',
  computingSetTip: 'Once the profile is created, the type cannot be changed.',
  computingSetChineTip:
    'It can only be used normally within the mainland China network environment. Once created, it cannot be modified. Choose carefully!',
  openEnvCombo: 'Package',
  computingManage: ' Computing Power',
  batchUpdateProxy: 'Batch Update Proxy',
  closeEnvTitle: 'Close Phone',
  batchCloseEnv: 'Batch Close Phone',
  editConfig: 'Edit Profile Configuration',
  openEnv: 'Opening Phone',
  envExpired: 'Subscription Expired',
  editNameWithSeq1: 'Update Profile Name (Seq: {seq})',
  batchOpenEnv: 'Batch Close Selected Phone',
  batchAllCloseEnv: 'Batch Close All Phone',
  todayPhone: 'Times',
  envName: 'Name',
  name: 'Name',
  envRmark: 'Profile Remarks',
  lastUpdateTime: 'Update Time',
  model: 'Model',
  createPeople: 'Creator',
  pkgEnvironment: 'Phone Profiles: {count}',
  buy: 'Purchase',
  openStatus: 'Open State',
  totalEnvironment: 'Profiles: {count}',
  pleaseSelect: 'Please select phone profile',
  customEnvName: 'Name the profile',
  nameTip: 'Name this profile for future daily management operations!',
  groupSelectTips:
    'Note: If you select a group under another account, the profile will be transferred to the group of other accounts (the ownership of the profile will belong to other accounts).',
  computeType0: `"Monthly Computing Power" will be used first. If no Monthly Computing Power is available, "Temporary Computing Power" will be used.`,
  computeType1: `Billing unit: {num}/15 minutes(capped at {maxNum}/day). Computing power is automatically released and billing stops on shutdown; billing continues during suspend.`,
  computeType2:
    'Automatically bind available Monthly computing power; one Profile uses one Monthly computing power. It can be unbound on shutdown for use by other Profiles.',
  computeType3:
    'Equipped with various sensors for a more realistic profile. Must be bound to Monthly Computing Power for use; the type can only be unbound after the profile expires.',
  remarkPlaceholder: 'Enter the profile remarks',
  ruleNameMsg: 'The profile name can have up to 30 characters',
  allPhoneClosed: 'All phone have been turned off',
  closePhone: 'The phone is closed.',
  checkedHasClosed: 'The selected phone is closed.',
  deleteTip: 'Once the profile is deleted, it cannot be recovered. Are you sure you want to permanently delete the profile?',
  batchDelect: 'Batch Delete Profiles',
  deleteCompletelybrowser: 'Permanently Delete Profile',
  computingStatus: 'Status',
  boundComputingTips: 'Shutdown and unbind Monthly computing power; the unbound Monthly computing power can be used by other Profiles.',
  releaseComputingTips: 'Shutdown and stop billing.',
  billingProgress: 'Billing',
  closeEnv: 'Release computing power',
  billingProgressTip: 'Computing power in use, continuous billing',
  boundComTip: 'Bound monthly computing power: {name}',
  releaseComputing: 'Release computing power',
  closeEnvStopCharging: 'Click "Confirm" to release computing power and stop billing. Do you want to confirm the shutdown?',
  closeEnvUnbindComputing:
    'Click "Confirm" to unbind the Monthly computing power and shut down. The unbound Monthly computing power can be used by other Profiles. Do you want to confirm the shutdown?',
  closeEnvBtn2: 'Shutdown',
  closeEnvBtn1: 'Shutdown',
  temporarilyClosed: 'Suspend',
  temporarilyClosed1: 'Suspend',
  temporarilyClosedTip1:
    '<span class="text-negative">Shutdown:</span> Close the profile window and shut down the cloud phone. Unbind the currently bound Monthly computing power for use by other profiles!',
  temporarilyClosedTip2:
    "<span class='text-negative'>Shutdown:</span> Close the profile window and shut down the cloud phone. Release Temporary computing power and stop billing!",
  unbindComputing: 'Unbind computing power',
  closeEnvTip1: 'Shutdown and unbind Monthly computing power. The profile might start a bit slower when restarted after shutdown!',
  closeEnvTip2: 'Shutdown and release Temporary computing power. The profile might start a bit slower when restarted after shutdown!',
  notMultipleOpen: 'The current profile does not allow multiple accounts to be opened simultaneously.',
  searchNamePlaceholder: 'Enter profile name',
  temporarilyClosedTip1_1:
    '<span class="text-primary">Suspend:</span> Only close the profile window; Monthly computing power remains bound, and the cloud phone continues to run.',
  temporarilyClosedTip2_1:
    "<span class='text-primary'>Suspend:</span> Only close the profile window; Temporary computing power continues to be billed, and the phone profile continues to run!",
  changeConfirm: `
    <p class="text-red text-bold">After subscription changes:</p>
    <p class="text-red q-mt-sm">1. The system will not refund and effect immediately.</p>
    <p class="text-red q-mt-sm">2. The number of phone profiles and one-click new phone instances will change to match the new plan.</p>
    <p class="q-mt-sm">Confirm to change?</p>
    `,
  againOpen: 'The phoen is already open by another user and cannot be opened again. Do you want to close this phone and reopen it?',
  envType: 'Type',
  envProxyTip: 'The phone profile must be configured with a valid proxy; otherwise, the phone profile cannot be accessed!',
  envChineProxyTip: 'The phone profile must be configured with a valid proxy; otherwise, the phone profile cannot be accessed!',
  selectComputing: 'Select Computing',
  languageTips: 'Generate the corresponding language based on IP. Customization available if not enabled.',
  billingMethod: 'Billing Method',
  selectBillingMethod: 'Please select a billing method',
  seq: 'Seq',
  udpLable: 'UDP Protocol',
  udpTip:
    'If you cannot connect to the network, uncheck UDP, which indicates that the network does not support UDP. By default, it is checked to protect your IP from being exposed.',
  createEnvTip: `The {name} profiles you purchased have reached the limit ({num}). To continue purchasing, you need to complete real-name authentication to lift this restriction.`,
  envChangeTip:
    'The number in the package represents the maximum number of cloud phone profiles you can create. Each cloud phone profile requires cloud computing power to run, which will incur additional cloud computing power costs. Please be aware!',
  maxEnvTip:
    'The total number of available profiles for the main account has been exceeded. Please purchase a profile package or upgrade to a larger profile package!',
  disabled: 'Disabled',
  disabledProfile: 'Disable profile',
  enableProfile: 'Enable profile',
  batchDisabledProfile: 'Disable profiles in bulk',
  batchEnableProfile: 'Enable profiles in bulk',
  createEnvMoneyTip: 'Insufficient balance and monthly computing power. Please top up or purchase more computing power.',
  networkError: 'Network Error...',
  apkFileError: 'Please Select a Correct APK Format File!',
  foreignNetworkTip:
    'The current profile is bound to the computing type: {regionName}, and is only supported for use with a global network environment!',
  domesticNetworkTip:
    'The current profile is bound to the computing power type: {regionName}, and can only be accessed with the local network set to mainland China.',
  createDomesticNetworkTip: 'Note: {arg} is only supported when the local network environment is set to mainland China.',
  createForeignNetworkTip: 'Note: {arg} is only supported when the local network environment is set to an overseas network.',
  phoneUpgradeTip:
    'Dear user, to enhance the stability and security of the cloud phone environment, the data center will undergo a system upgrade on November 8 at 00:00 Beijing Time. The upgrade is expected to take 3 hours. During this period, the cloud phone functionality will be unavailable. We recommend shutting down all cloud phones. Thank you for your understanding and cooperation!',
  phoneUpgradeLoadingTip:
    'The data center will undergo a system upgrade on November 8 at 00:00 Beijing Time, which is expected to take 3 hours. Please be patient!'
}
