export default {
  dialogTitle: 'Запрос на вывод денег',
  balance: 'Доступная для вывода сумма:',
  payType: 'Способ вывода',
  payMoney: 'Сумма вывода',
  payMoneyPlaceholder: 'Введите сумму вывода',
  quickCheckPlaceholder: 'Быстрый выбор информации о переводе',
  quickCheck: 'Быстрый выбор',
  alipayPlaceholder: 'Введите номер счета Alipay',
  cardPlaceholder: 'Введите номер банковской карты',
  alipay: 'Номер счета Alipay',
  cardNo: 'Номер банковской карты',
  paypal: 'Номер счета PayPal',
  bankPlaceholder: 'Введите название банка',
  bank: 'Название банка',
  usernamePlaceholder: 'Введите фамилию и имя',
  bankUsernamePlaceholder: 'Введите  фамилию и имя владельца счета',
  username: 'Фамилия и имя',
  bankUsername: 'Фамилия и имя владельца счета',
  moneyRuleMsg1: 'Введите сумму вывода',
  moneyRuleMsg2: 'Сумма вывода должна быть больше 0 и не превышать два знака после запятой',
  moneyRuleMsg3: 'Сумма вывода не может превышать доступную сумму',
  alipayRuleMsg1: 'Введите номер счета Alipay',
  alipayRuleMsg2: 'Номер счета Alipay должен быть номером мобильного телефона или адресом электронной почты',
  alipayRuleMsg3: 'Номер счета Alipay не может превышать 50 символов',
  alipayUsernameRule1: 'Введите фамилию и имя владельца счета Alipay',
  alipayUsernameRule2: 'Фамилия и имя владельца счета Alipay не могут превышать 30 символов',
  cardRule1: 'Введите номер банковской карты',
  cardRule2: 'Введите корректный номер банковской карты',
  cardRule3: 'Номер банковской карты не может превышать 30 символов',
  bankRule1: 'Введите название банка',
  bankRule2: 'Название банка не должно содержать специальных символов',
  bankRule3: 'Название банка не может превышать 64 символа',
  bankUsernameRule1: 'Введите фамилию и имя владельца счета',
  bankUsernameRule2: 'Фамилия и имя владельца счета не должны содержать специальных символов',
  bankUsernameRule3: 'Фамилия и имя владельца счета не могут превышать 64 символа',
  withBalance: 'Вывести остаток средств в платежный центр',
  withCard: 'Вывести на банковскую карту',
  withAlipay: 'Вывести на Alipay',
  withPaypal: 'Вывести на PayPal',
  requestSuccess: 'Запрос на вывод денег успешно отправлен, ожидайте проверку в фоновом режиме',
  paypalPlaceholder: 'Введите номер счета PayPal',
  withdrawMinMoney: 'Сумма снятия должна превышать {num}'
}
